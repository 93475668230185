import { useEffect } from 'react';

const FreshworksWidget: React.FC = () => {
  const widgetId = process.env.REACT_APP_CADENCE_FRESHDESK_WIDGET_ID as string;

  useEffect(() => {
    // Ensure `fwSettings` is globally available before loading the script
    (window as any).fwSettings = {
      widget_id: widgetId,
    };

    // Create the external script element
    const script = document.createElement('script');
    script.src = `https://ind-widget.freshworks.com/widgets/${widgetId}.js`;
    script.async = true;
    script.defer = true;

    document.head.appendChild(script);

    // Cleanup script on unmount
    return () => {
      document.head.removeChild(script);
    };
  }, [widgetId]);

  return null; // No UI for the component
};

export default FreshworksWidget;
