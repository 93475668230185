import { gql } from '@apollo/client';

const CurrentUserQuery = gql`
  query {
    me @rest(type: "User", path: "users/me") {
      data
      includedAssociations
    }
  }
`;

export const FETCH_ORG_LICENSE_QUERY = gql`
  query {
    license @rest(type: "License", path: "license") {
      data
    }
  }
`;

export default CurrentUserQuery;
